define("discourse/plugins/discourse-onboarding-banner/discourse/connectors/discovery-above/onboarding-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      return component.currentUser?.show_onboarding_banner && component.siteSettings.discourse_onboarding_banner_enabled;
    }
  };
});