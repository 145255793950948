define("discourse/plugins/discourse-onboarding-banner/discourse/templates/connectors/discovery-above/onboarding-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <OnboardingBanner />
  */
  {
    "id": "F0rdyFuM",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"onboarding-banner\"]]",
    "moduleName": "discourse/plugins/discourse-onboarding-banner/discourse/templates/connectors/discovery-above/onboarding-banner.hbs",
    "isStrictMode": false
  });
});